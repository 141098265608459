<i18n lang="yaml">
pt:
  triggerTypeLabel: 'Forma de iniciar a automação'
en:
  triggerTypeLabel: 'How to trigger the automation'
</i18n>
<template>
  <deck-select
    ref="select"
    :model-value="modelValue"
    :label="t('triggerTypeLabel')"
    :items="items"
    required
    :disabled="disabled"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>
<script>
import { AVAILABLE_TRIGGER_TYPES, TRIGGER_TYPES } from '~/assets/javascript/constants';
import DeckSelect from '~/deck/select';

export default {
  name: 'WorkflowTriggerTypeSelect',
  components: {
    DeckSelect,
  },
  props: {
    /**
     * The modelValue of the select.
     * @type {string}
     * @default null
     */
    modelValue: {
      type: String,
      default: null,
    },

    /**
     * Whether the select is disabled.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  computed: {
    items() {
      return AVAILABLE_TRIGGER_TYPES
        .map(type => ({
          text: this.$t(`workflowTriggerTypes.${type}`),
          value: type,
          icon: TRIGGER_TYPES[type].icon,
          iconKind: TRIGGER_TYPES[type].iconKind,
        }));
    },
  },
};
</script>
