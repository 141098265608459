<i18n lang="yaml">
pt:
  addKey: "Adicionar chave"
  copyUrl: "Copiar URL"
  explainKeys: "Selecione quais chaves você quer extrair do corpo da requisição"
  explainUrl: "Use esta URL com uma chamada do tipo POST para iniciar a execução da automação"
  key: "Chave"
  removeKey: "Remover chave"
  urlCopiedSuccessfully: "URL copiada com sucesso"
en:
  addKey: "Add key"
  copyUrl: "Copy URL"
  explainKeys: "Choose which keys do you want to extract from the body request"
  explainUrl: "Use this URL with a POST request to start the automation execution"
  key: "Key"
  removeKey: "Remove key"
  urlCopiedSuccessfully: "URL copied successfully"
</i18n>
<template>
  <div>
    <deck-text-field
      v-model="currentTrigger.webhook_url"
      outlined
      disabled
      label="URL"
      class="mb-2 cursor-pointer"
      :hint="t('explainUrl')"
      @click="copyUrlToClipboard"
    >
      <template #append>
        <deck-button
          kind="ghost"
          icon="copy"
          size="small"
          color="controls"
          :aria-label="t('copyUrl')"
        />
      </template>
    </deck-text-field>

    <p>{{ t('explainKeys') }}</p>

    <deck-text-field
      v-for="(_key, index) in paramsMap"
      :key="index"
      v-model="paramsMap[index]"
      :label="`${t('key')} #${index+1}`"
      :placeholder="t('key')"
      class="mb-2"
    >
      <template #append>
        <deck-button
          kind="ghost"
          icon="trash"
          size="small"
          color="controls"
          :aria-label="t('removeKey')"
          @click="removeKeyValue(index)"
        />
      </template>
    </deck-text-field>

    <v-row class="ma-0 pb-2">
      <v-btn
        color="primary"
        variant="text"
        @click="addKey"
      >
        <v-icon start>
          fa-plus fa-regular
        </v-icon>
        {{ t('addKey') }}
      </v-btn>

      <v-btn
        color="primary"
        variant="text"
        class="elevation-0"
        :disabled="paramsMapNotChanged"
        @click="updateParamsMap"
      >
        {{ $t('global.save') }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from '~/assets/javascript/modules/vuex';
import DeckTextField from '~/deck/text-field';
import DeckButton from '~/deck/button';

export default {
  name: 'WebhookTriggerEditor',
  components: { DeckTextField, DeckButton },
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workflows', ['currentTrigger']),
      ...mapActions('workflows', ['updateTrigger']),
    };
  },
  data() {
    return {
      paramsMap: [],
    };
  },
  computed: {
    paramsMapNotChanged() {
      const currentParamsMap = [...this.currentTrigger.params_map];
      return _isEqual(this.newParamsMap, currentParamsMap);
    },
    newParamsMap() {
      return this.paramsMap.filter(Boolean);
    },
  },
  mounted() {
    this.paramsMap = [...this.currentTrigger.params_map];
  },
  methods: {
    copyUrlToClipboard() {
      navigator.clipboard.writeText(this.currentTrigger.webhook_url);

      this.$notifier.showMessage({
        content: this.t('urlCopiedSuccessfully'),
        color: 'success',
      });
    },
    addKey() {
      this.paramsMap.push('');
    },
    removeKeyValue(index) {
      this.paramsMap.splice(index, 1);
    },
    updateParamsMap() {
      this.updateTrigger({
        paramsMap: this.newParamsMap,
        workflowTriggerId: this.currentTrigger.id,
      });
    },
  },
};
</script>
