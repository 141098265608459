<template>
  <div class="workflow-step-editor-loader">
    <v-card
      v-for="i in 3"
      :key="i"
    >
      <div class="d-flex">
        <v-skeleton-loader
          loading
          type="avatar"
        />

        <v-skeleton-loader
          loading
          type="heading"
          width="60%"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'WorkflowStepEditorLoader',
};
</script>

<style lang="scss">
.workflow-step-editor-loader {
  display: flex;
  flex-direction: column;
  gap: 28px; // simulate the gap between the steps

  .v-skeleton-loader__avatar {
    max-width: 20px;
    max-height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
  }

  .v-skeleton-loader__heading {
    height: 20px;
  }
}
</style>
