<template>
  <v-card>
    <div class="workflow-trigger-editor-loader__wrapper">
      <v-skeleton-loader
        type="heading"
        width="45%"
        loading
      />
      <v-skeleton-loader
        type="subtitle"
        width="90%"
        loading
      />

      <v-skeleton-loader
        type="button"
        width="100%"
        loading
      />

      <v-skeleton-loader
        type="button"
        width="100%"
        loading
      />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'WorkflowTriggerEditorLoader',
};
</script>

<style lang="scss">

.workflow-trigger-editor-loader__wrapper {
  .v-skeleton-loader {
    width: 100%;
  }

  .v-skeleton-loader__button {
    max-width: 100%;
  }
}
</style>
