<i18n lang="yaml">
pt:
  blankMessage: 'Nada aqui ainda'
  createFirstWorkflow: 'Crie sua primeira automação'
  pageTitle: 'Automações'
  hideAllActions: 'Esconder todas as ações'
  showAllActions: 'Mostrar todas as ações'
en:
  blankMessage: 'Nothing here yet'
  createFirstWorkflow: 'Create your first automation'
  pageTitle: 'Automations'
  hideAllActions: 'Hide all actions'
  showAllActions: 'Show all actions'
</i18n>

<template>
  <AutomationsLayout>
    <v-container
      v-if="showBlankMessage"
      class="fill-height"
    >
      <v-row
        align="center"
        justify="center"
        class="flex-column"
      >
        <div class="mb-2">
          {{ t('blankMessage') }} 😊
        </div>
        <v-btn
          color="primary"
          elevation="0"
          @click="openDialogNewWorkflow"
        >
          {{ t('createFirstWorkflow') }}
        </v-btn>
      </v-row>
    </v-container>

    <div v-else>
      <div class="automations__toolbar">
        <v-skeleton-loader
          v-if="loadingCurrentWorkflow"
          v-test-id="'workflow-title-loader'"
          class="automations__workflow-title-loader"
          loading
          type="heading"
          width="40%"
        />
        <h1
          v-else
          class="automations__workflow-title"
        >
          <deck-icon
            name="bolt"
            size="large"
          />
          {{ currentWorkflow.name }}
        </h1>

        <template v-if="!loadingWorkflowSteps">
          <deck-button
            :text="t('showAllActions')"
            :tooltip-props="{ position: 'bottom' }"
            is-ready
            icon="eye"
            kind="secondary"
            color="controls"
            @click="setStepsVisibility(true)"
          />
          <deck-button
            :text="t('hideAllActions')"
            :tooltip-props="{ position: 'bottom' }"
            is-ready
            icon="eye-slash"
            kind="secondary"
            color="controls"
            @click="setStepsVisibility(false)"
          />
        </template>
      </div>

      <WorkflowTriggerEditorLoader
        v-if="loadingCurrentWorkflow"
        v-test-id="'workflow-trigger-loader'"
      />
      <TriggerEditor
        v-else
        v-test-id="'workflow-trigger'"
      />

      <WorkflowStepEditorLoader
        v-if="loadingWorkflowSteps"
        v-test-id="'workflow-steps-loader'"
        class="mt-7"
      />
      <div
        v-else
        v-test-id="'workflow-steps'"
      >
        <StepEditor
          v-for="step in currentWorkflow.sorted_steps"
          :key="step.id"
          :step="step"
          is-root
        />
      </div>
    </div>

    <template #options>
      <WorkflowsOptions />
    </template>
  </AutomationsLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from '~/assets/javascript/modules/vuex';
import AutomationsLayout from '~/components/studio/workflows/layout';
import WorkflowStepEditorLoader from '~/components/studio/workflows/step-editor-loader';
import WorkflowTriggerEditorLoader from '~/components/studio/workflows/trigger-editor-loader';
import WorkflowsOptions from '~/components/studio/WorkflowsOptions';
import TriggerEditor from '~/components/studio/workflows/trigger-editor';
import StepEditor from '~/components/studio/workflows/StepEditor';
import middleware from '~/middleware/automations/middleware';

export default {
  name: 'Automations',
  components: {
    AutomationsLayout,
    StepEditor,
    TriggerEditor,
    WorkflowStepEditorLoader,
    WorkflowTriggerEditorLoader,
    WorkflowsOptions,
  },
  setup() {
    definePageMeta({
      layout: 'studio',
      middleware: [
        'auth',
        'refresh-user',
        'is-admin',
        middleware,
      ],
    });

    const vuex = {
      ...mapState('workflows', ['loadingCurrentWorkflow', 'loadingWorkflowSteps']),
      ...mapGetters('workflows', ['currentWorkflow']),
      ...mapActions('workspace', ['loadWorkspace']),
      ...mapMutations('dialog', ['openDialog']),
      ...mapMutations('workflows', ['setStepsVisibility']),
    };
    const { t } = useI18n();

    buildHead({
      title: computed(() => (_isEmpty(vuex.currentWorkflow.value)
        ? t('pageTitle')
        : vuex.currentWorkflow.value.name)),
    });

    return {
      t,
      ...vuex,
    };
  },
  computed: {
    showBlankMessage() {
      return !this.loadingCurrentWorkflow && (!this.currentWorkflow.id || this.currentWorkflow.id !== this.$route.params.id);
    },
  },
  methods: {
    openDialogNewWorkflow() {
      this.openDialog({
        component: 'DialogNewWorkflow',
        on: {
          onDataChanged: async () => {
            await this.loadWorkspace({ builder: true });
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
.automations__toolbar {
  flex: none;
  position: sticky;
  top: 0;
  height: 64px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-inline: -4px;
  padding-inline: 4px;
  background-color: var(--z-main-background-accent-color);
  z-index: 2;
}

.automations__workflow-title-loader {

  & > .v-skeleton-loader__bone {
    margin: 0;
  }
}

.automations__workflow-title {
  font-size: 20px;
  margin-right: auto;
}
</style>
