<i18n lang="yaml">
pt:
  day: 'Dia'
  frequency:
    daily: 'Diariamente'
    every_five_minutes: 'A cada 5 minutos'
    monthly: 'Mensalmente'
    weekly: 'Semanalmente'
  run: 'Executar:'
  hour: 'Hora'
  minute: 'Minuto'
  monthDays: 'Dias do mês:'
  weekdays: 'Dias da semana:'
  sunday: 'Domingo'
  monday: 'Segunda'
  tuesday: 'Terça'
  wednesday: 'Quarta'
  thursday: 'Quinta'
  friday: 'Sexta'
  saturday: 'Sábado'
  lastDay: 'Último dia'
en:
  day: 'Day'
  frequency:
    daily: 'Daily'
    every_five_minutes: 'Every 5 minutes'
    monthly: 'Monthly'
    weekly: 'Weekly'
  run: 'Run:'
  hour: 'Hour'
  minute: 'Minute'
  monthDays: 'Month days:'
  weekdays: 'Weekdays:'
  sunday: 'Sunday'
  monday: 'Monday'
  tuesday: 'Tuesday'
  wednesday: 'Wednesday'
  thursday: 'Thursday'
  friday: 'Friday'
  saturday: 'Saturday'
  lastDay: 'Last day'
</i18n>
<template>
  <div>
    <div class="d-flex flex-start justify-start flex-wrap">
      <div class="mr-6 d-flex align-center">
        <deck-select
          v-model="frequency"
          :label="t('run')"
          :items="frequencyOptions"
          :disabled="frequencySelectDisabled"
          unordered-items
          class="scheduling-selector__select"
        />

        <template v-if="frequency !== 'every_five_minutes'">
          <deck-select
            v-model="inputHour"
            :items="hourOptions"
            :label="t('hour')"
            class="scheduling-selector__select ml-4"
          />

          <deck-select
            v-model="minute"
            :items="minuteOptions"
            :label="t('minute')"
            class="scheduling-selector__select ml-4"
          />
        </template>
      </div>
    </div>

    <v-expand-transition>
      <div v-if="frequency === 'weekly'">
        <div class="pt-4">
          <p class="mb-1">
            {{ t('weekdays') }}
          </p>

          <v-chip-group
            v-model="weekdays"
            color="primary"
            multiple
            column
            mandatory
          >
            <v-chip
              v-for="weekday in weekdaysOptions"
              :key="weekday"
              :value="weekday"
            >
              {{ t(weekday) }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <deck-select
        v-if="frequency === 'monthly'"
        v-model="days"
        :items="daysOptions"
        multiple
        :rules="[(v) => v.length > 0 || $t('global.emptyGeneric')]"
        :label="t('monthDays')"
        class="scheduling-selector__select pt-4"
      />
    </v-expand-transition>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapActions, mapGetters } from '~/assets/javascript/modules/vuex';
import DeckSelect from '~/deck/select';

const generateSequentialNumberArray = (quantity, start = 1) => Array.from(
  Array(quantity + start).keys(),
).splice(start);

const currentDate = new Date();
const MINUTE_STEP = 10;
const step = Math.floor(currentDate.getMinutes() / MINUTE_STEP);
const minuteStepped = step * MINUTE_STEP;

const DEFAULT_SCHEDULING_DATA = {
  frequency: 'daily',
  minute: minuteStepped,
  hour: currentDate.getHours(),
};

const PERMITTED_SCHEDULING_DATA = [
  'frequency',
  'minute',
  'hour',
  'days',
  'weekdays',
];

const WEEKDAYS_OPTIONS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export default {
  name: 'SchedulingSelector',
  components: { DeckSelect },
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workflows', ['currentTrigger']),
      ...mapGetters('workspace', ['timeZone']),
      ...mapActions('workflows', ['loadWorkflows', 'reloadCurrentWorkflow']),
    };
  },
  data() {
    return {
      ...DEFAULT_SCHEDULING_DATA,
      enabledFrequencySelectOptions: ['daily', 'weekly', 'monthly'],
      disabledFrequencySelectOptions: ['every_five_minutes'],
      minuteOptions: this.formattedSelectNumberItems([0, 10, 20, 30, 40, 50]),
      hourOptions: this.formattedSelectNumberItems(generateSequentialNumberArray(24, 0)),
      days: [currentDate.getDate()],
      daysOptions: [
        ...this.formattedSelectNumberItems(generateSequentialNumberArray(31)),
        { text: this.t('lastDay'), value: 'last_day' },
      ],
      weekdays: [WEEKDAYS_OPTIONS[currentDate.getDay()]],
      weekdaysOptions: WEEKDAYS_OPTIONS,
      skipUpdate: false,
    };
  },
  computed: {
    inputHour: {
      get() {
        return moment.utc().hour(this.hour).tz(this.timeZone).hour();
      },
      set(value) {
        this.hour = moment.tz(this.timeZone).hour(value).utc().hour();
      },
    },
    scheduling() {
      const data = {
        frequency: this.frequency,
        minute: this.minute,
        hour: this.hour,
      };

      if (this.frequency === 'weekly') {
        data.weekdays = this.weekdays;
      } else if (this.frequency === 'monthly') {
        data.days = this.days;
      }

      return data;
    },
    frequencyOptions() {
      if (this.frequencySelectDisabled) {
        return this.disabledFrequencySelectOptions.map(value => ({
          text: this.t(`frequency.${value}`),
          value,
        }));
      }

      return this.enabledFrequencySelectOptions.map(value => ({
        text: this.t(`frequency.${value}`),
        value,
      }));
    },
    frequencySelectDisabled() {
      return this.frequency === 'every_five_minutes';
    },
  },
  watch: {
    async scheduling(scheduling) {
      if (this.skipUpdate) return;
      if (_isEqual(scheduling, this.currentTrigger.scheduling)) return;

      await this.updateTriggerScheduling();
    },
    currentTrigger: {
      immediate: true,
      async handler(newData, oldData) {
        if (_isEqual(newData.scheduling, oldData?.scheduling)) return;

        const permittedData = _pick(newData.scheduling, PERMITTED_SCHEDULING_DATA);

        this.skipUpdate = true;

        Object.entries(permittedData).forEach(([key, value]) => {
          this[key] = value;
        });

        await nextTick();

        this.skipUpdate = false;
      },
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    updateTriggerScheduling: _throttle(async function () {
      window.analytics.track('updateTrigger', {
        newState: this.scheduling,
        property: 'scheduling',
      });

      try {
        await this.$api.patch(
          `/workflow_triggers/${this.currentTrigger.id}`,
          { body: { scheduling: this.scheduling } },
        );

        await this.reloadCurrentWorkflow();
        await this.loadWorkflows();
      } catch (error) {
        this.$errorRescue(this, error, 'updateTrigger');
      }
    }, 200),
    formattedSelectNumberItems(numbers) {
      return numbers.map((number) => {
        const numberFormatted = number.toString().padStart(2, '0');
        return {
          text: numberFormatted,
          value: number,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.scheduling-selector__select {
  max-width: 300px;
  min-width: 200px;
}
</style>
